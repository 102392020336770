import { isDefined } from '~/utils/guards/isDefined';

export type SearchTagModel = {
  label?: string;
  value: string;
};

export function createDictKeysForSearchTags(
  searchTags: ReadonlyArray<string | null | undefined> | null | undefined
): { key: string; type: 'text' }[] {
  return (
    searchTags
      ?.filter(isDefined)
      .map(createTagDictKey)
      .map(mapToTextDictKeyObject) ?? []
  );
}

export function createTagDictKey(tag: string): string {
  return `searchSection.searchResults.tag.${tag}`;
}

function mapToTextDictKeyObject(key: string) {
  return { key, type: 'text' as const };
}

export function createSearchTags(
  tags: ReadonlyArray<string | undefined | null> | null | undefined,
  dictionary: Record<string, string | undefined>,
  locale = 'en-US'
): SearchTagModel[] {
  return (
    tags
      ?.filter(isDefined)
      .map((tag) => ({
        label: dictionary[createTagDictKey(tag)] || tag,
        value: tag
      }))
      .sort((a, b) => a.label.localeCompare(b.label, locale)) ?? []
  );
}
